export const ROUTES = {
  ALL_PRODUCTS: '/alle-produkte',
  VENDOR: '/anbieter',
  PRODUCT_DETAILS: '/produkt',
  SIGN_IN: '/anmeldung',
  MAGIC_LINK_SENT: '/link-gesendet',
  MAGIC_LINK_CONFIRMATION: '/auth',
  CONTACT: '/kontakt',
  FAQ: '/faq',
  REVOCATION: '/widerrufsbelehrung',
  IMPRINT: '/impressum',
  ERROR_500: '/fehler',
  CART: '/warenkorb',
  CHECKOUT: '/kasse',
  REGISTRATION: '/registrierung',
  REGISTRATION_EMAIL: 'email',
  REGISTRATION_EMAIL_ACTIVATION_LINK_SENT: 'aktivierungslink-gesendet',
  REGISTRATION_EMAIL_CONFIRMATION: 'email-bestaetigung',
  REGISTRATION_MOBILE_PHONE_NUMBER: 'mobilnummer',
  REGISTRATION_OTP_CONFIRMATION: 'tan-ueberpruefung',
  REGISTRATION_BANK_VERIFICATION: 'bank-ueberpruefung',
  REGISTRATION_BANK_VERIFICATION_PENDING: 'bank-ueberpruefung-in-bearbeitung',
  REGISTRATION_PERSONAL_DATA: 'persoenliche-daten',
  REGISTRATION_ADDITIONAL_PERSONAL_DATA: 'zusaetzliche-daten',
  REGISTRATION_ADDITIONAL_DOCUMENTS: 'zusaetzliche-dokumente',
  REGISTRATION_IDENTIFICATION: 'identifikation',
  USER_DECLINED: '/registrierung-abgelehnt',
  BNPL_ORDER_DECLINED: '/kauf-auf-rechnung-abgelehnt',
  UNVERIFIED_IDENTITY: '/unverifiziert-identitaet',
  ORDER_PENDING: '/bestellung-in-bearbaitung',
  ORDER_SUCCESSFUL: '/bestellung-erfolgreich',
  ORDER_PAYMENT_REDIRECTION: '/bestellung-zahlungsumleitung',  
  STRIPE_PAYMENT_INTERRUPTED: '/zahlung-abgebrochen',
  STRIPE_PROCESSING_PAYMENT: '/zahlung-in-bearbeitung',
  DASHBOARD: '/mein-konto',
  DASHBOARD_ORDERS: 'bestellungen',
  DASHBOARD_ORDER: 'bestellung',
  DASHBOARD_INVOICES: 'rechnungen',
  DASHBOARD_USER_PROFILE: 'profil',
}

export const DEFAULT_LIMITS = {
  BNPL: 50,
  PREPAID: 150,
  PAYSAFECARD: 100,
  KLARNA_OPEN_ORDERS: 250,
  PAYPAL_OPEN_ORDERS: 250,
}

export const PAYMENT_METHODS = {
  KREDU_BNPL: 'KREDU_BNPL',
  PREPAID: 'SOFORT',
  STRIPE_KLARNA: 'STRIPE_KLARNA',
  STRIPE_PAYPAL: 'STRIPE_PAYPAL',
}

export const REGISTRATION_STEPS = {
  EMAIL: 'EMAIL',
  MOBILE_PHONE_NUMBER: 'MOBILE_PHONE_NUMBER',
  BANK_VERIFICATION: 'BANK_VERIFICATION',
  PERSONAL_DATA: 'PERSONAL_DATA',
  ADDITIONAL_PERSONAL_DATA: 'ADDITIONAL_PERSONAL_DATA',
  ADDITIONAL_DOCUMENTS: 'ADDITIONAL_DOCUMENTS',
  IDENTIFICATION: 'IDENTIFICATION',
}

export const ERROR_MESSAGES = {
  DEFAULT_ERROR_MESSAGE:
    'Es ist ein Fehler aufgetreten. Bitte versuche es erneut oder kontaktiere unser Support-Team.',
}
