import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import productService from '../../product/services/productService'
import userService from '../../../services/userService'
import cryptoJS from 'crypto-js'
import cartService from '../services/cartService'
import { DEFAULT_LIMITS, PAYMENT_METHODS } from '../../../data/constants'

let cartFromStorage = null

if (localStorage.getItem('cart')) {
  try {
    cartFromStorage = JSON.parse(
      cryptoJS.AES.decrypt(
        localStorage.getItem('cart'),
        process.env.REACT_APP_KREDU_SHOP_LOCAL_STORAGE_ENCRYPTION_KEY
      ).toString(cryptoJS.enc.Utf8)
    )
    if (
      !cartFromStorage ||
      !cartFromStorage.paysafecardAvailableLimit ||
      !cartFromStorage.paysafecardLimit
    ) {
      localStorage.removeItem('cart')
      cartFromStorage = null
    }
  } catch {
    localStorage.removeItem('cart')
  }
}

const initialState = cartFromStorage ?? {
  cartItems: [],
  serviceFee: 0,
  serviceFeeVat: 0,
  kredupayDiscount: false,
  kredupayDiscountAmount: 0,
  discount: 0,
  prepaidOrderLimit: DEFAULT_LIMITS.PREPAID,
  bnplOrderLimit: DEFAULT_LIMITS.BNPL,
  amountOfOpenOrders: 0,
  totalAmountWithoutServiceFee: 0,
  totalAmount: 0,
  paymentMethod: PAYMENT_METHODS.STRIPE_KLARNA,
  isCartSubmitted: false,
  isSubmitError: false,
  isSubmitLoading: false,
  isLoading: false,
  isSuccess: false,
  isError: false,
  message: '',
  recentlyAddedCartProductId: 0,
  hasOpenPaysLegacyInvoices: false,
  hasOpenKredupayInvoices: false,
  hasOverdueInvoices: false,
  paysafecardLimit: DEFAULT_LIMITS.PAYSAFECARD,
  paysafecardAvailableLimit: 0,
  isPaysafecardLimitExceeded: false,
  klarnaOrdersAvailableLimit: 0,
  isOpenKlarnaOrdersLimitExceeded: false,
  payPalOrdersAvailableLimit: 0,
  isOpenPayPalOrdersLimitExceeded: false,
}

initialState.paymentMethod = PAYMENT_METHODS.STRIPE_KLARNA

export const loadProductDataOrderLimitDiscountAndOpenOrdersAmount =
  createAsyncThunk(
    'cart/loadProductDataOrderLimitDiscountAndOpenOrdersAmount',
    async (params, thunkAPI) => {
      try {
        const user = thunkAPI.getState().auth.user
        const cartItems = thunkAPI.getState().cart.cartItems
        const productData = await productService.loadProductData(
          cartItems.map((x) => x.productId)
        )

        if (user && user.userId) {
          const cartParameters = await userService.getUserCartParameters(
            user.token
          )

          return {
            productData: productData,
            bnplOrderLimit: cartParameters.bnplOrderLimit,
            prepaidOrderLimit: cartParameters.prepaidOrderLimit,
            hasKredupayDiscount: cartParameters.hasKredupayDiscount,
            amountOfOpenOrders: cartParameters.totalAmountOfOpenOrders,
            hasOpenPaysLegacyInvoices: cartParameters.hasOpenPaysLegacyInvoices,
            hasOpenKredupayInvoices: cartParameters.hasOpenKredupayInvoices,
            hasOverdueInvoices: cartParameters.hasOverdueInvoices,
            paysafecardLimit: cartParameters.paysafecardOrderLimit,
            paysafecardAvailableLimit: cartParameters.paysafecardAvailableLimit,
            klarnaOrdersAvailableLimit:
              cartParameters.klarnaOrdersAvailableLimit,
            payPalOrdersAvailableLimit:
              cartParameters.payPalOrdersAvailableLimit,
          }
        }

        return {
          productData: productData,
        }
      } catch (error) {
        let errorMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()

        if (error.response.status === 401) {
          errorMessage = '401'
        }
        return thunkAPI.rejectWithValue(errorMessage)
      }
    }
  )

export const submitCart = createAsyncThunk(
  'cart/submitCart',
  async (params, thunkAPI) => {
    try {
      let token = null
      if (thunkAPI.getState().auth && thunkAPI.getState().auth.user) {
        token = thunkAPI.getState().auth.user.token
      }
      const paymentMethod = thunkAPI.getState().cart.paymentMethod
      const cartItems = thunkAPI
        .getState()
        .cart.cartItems.filter((x) => x.isInStock === true)
        .map((x) => {
          return {
            productId: x.productId,
            quantity: x.quantity,
          }
        })
      return await cartService.submitCart(token, cartItems, paymentMethod)
    } catch (error) {
      const errorMessage =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()

      return thunkAPI.rejectWithValue(errorMessage)
    }
  }
)

export const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    addToCart: (state, action) => {
      state.isLoading = true

      try {
        const newItem = action.payload

        const existingItem = state.cartItems.find(
          (x) => x.productId === newItem.productId
        )

        if (existingItem) {
          existingItem.quantity += newItem.quantity
        } else {
          state.cartItems = [...state.cartItems, newItem]
        }

        state.recentlyAddedCartProductId = newItem.recentlyAddedCartProductId

        saveStateInLocalStorage(state)

        state.isLoading = false
        state.isSuccess = true
      } catch (error) {
        state.isLoading = false
        state.isSuccess = false
        state.isError = true
        state.message = error.message || error.toString()
      }
    },
    reset: (state) => {
      state.isLoading = false
      state.isSubmitLoading = false
      state.isSubmitError = false
      state.isError = false
      state.isValidationError = false
      state.isSuccess = false
      state.message = ''
    },
    resetRecentlyAddedCartProductId: (state) => {
      state.recentlyAddedCartProductId = 0
    },
    setPaymentMethodInCart: (state, action) => {
      state.paymentMethod = action.payload
      calculateServiceFeeDiscountAndTotalAmount(state)
      saveStateInLocalStorage(state)
    },
    increaseCartItemQuantity: (state, action) => {
      const cartItem = state.cartItems.find(
        (x) => x.productId === action.payload.productId
      )
      if (cartItem) {
        cartItem.quantity = action.payload.newQuantity
        calculateServiceFeeDiscountAndTotalAmount(state)
        saveStateInLocalStorage(state)
      }
    },
    decreaseCartItemQuantity: (state, action) => {
      const cartItem = state.cartItems.find(
        (x) => x.productId === action.payload.productId
      )
      if (cartItem) {
        cartItem.quantity =
          action.payload.newQuantity > 1 ? action.payload.newQuantity : 1
        calculateServiceFeeDiscountAndTotalAmount(state)
        saveStateInLocalStorage(state)
      }
    },
    removeCartItem: (state, action) => {
      state.cartItems = state.cartItems.filter(
        (x) => x.productId !== action.payload
      )
      calculateServiceFeeDiscountAndTotalAmount(state)
      saveStateInLocalStorage(state)
    },
    clearCart: (state) => {
      state.cartItems = []
      state.serviceFee = 0
      state.kredupayDiscount = false
      state.kredupayDiscountAmount = 0
      state.discount = 0
      state.prepaidOrderLimit = DEFAULT_LIMITS.PREPAID
      state.bnplOrderLimit = DEFAULT_LIMITS.BNPL
      state.amountOfOpenOrders = 0
      state.totalAmountWithoutServiceFee = 0
      state.totalAmount = 0
      state.paymentMethod = PAYMENT_METHODS.KREDU_BNPL
      state.isCartSubmitted = false
      state.isSubmitError = false
      state.isSubmitLoading = false
      state.isLoading = false
      state.isSuccess = false
      state.isError = false
      state.message = ''
      state.recentlyAddedCartProductId = 0

      localStorage.removeItem('cart')
    },
    revertCartSubmit: (state) => {
      state.isLoading = true
      state.isCartSubmitted = false
      state.isLoading = false
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        loadProductDataOrderLimitDiscountAndOpenOrdersAmount.pending,
        (state) => {
          state.isLoading = true
        }
      )
      .addCase(
        loadProductDataOrderLimitDiscountAndOpenOrdersAmount.fulfilled,
        (state, action) => {
          if (Array.isArray(action.payload.productData)) {
            action.payload.productData.forEach((product) => {
              const cartItem = state.cartItems.find(
                (x) => x.productId === product.id
              )
              cartItem.name = product.name
              cartItem.description = product.description
              cartItem.price = product.price
              cartItem.slug = product.slug
              cartItem.isInStock = product.isInStock
              cartItem.productType = product.productType
            })
          }

          if (localStorage.getItem('user')) {
            state.bnplOrderLimit =
              action.payload.bnplOrderLimit - action.payload.amountOfOpenOrders
            state.prepaidOrderLimit =
              action.payload.prepaidOrderLimit -
              action.payload.amountOfOpenOrders
            state.kredupayDiscount = action.payload.hasKredupayDiscount
            state.amountOfOpenOrders = action.payload.amountOfOpenOrders
            if (state.bnplOrderLimit < 0) state.bnplOrderLimit = 0
            if (state.prepaidOrderLimit < 0) state.prepaidOrderLimit = 0
            state.hasOpenPaysLegacyInvoices =
              action.payload.hasOpenPaysLegacyInvoices
            state.hasOpenKredupayInvoices =
              action.payload.hasOpenKredupayInvoices
            state.hasOverdueInvoices = action.payload.hasOverdueInvoices
            state.paysafecardLimit = action.payload.paysafecardLimit
            state.paysafecardAvailableLimit =
              action.payload.paysafecardAvailableLimit
            state.klarnaOrdersAvailableLimit =
              action.payload.klarnaOrdersAvailableLimit
            state.payPalOrdersAvailableLimit =
              action.payload.payPalOrdersAvailableLimit
          } else {
            state.kredupayDiscount = false
            state.kredupayDiscountAmount = 0
            state.bnplOrderLimit = DEFAULT_LIMITS.BNPL
            state.prepaidOrderLimit = DEFAULT_LIMITS.PREPAID
            state.amountOfOpenOrders = 0
            state.hasOpenPaysLegacyInvoices = false
            state.hasOpenKredupayInvoices = false
            state.hasOverdueInvoices = false
            state.paysafecardLimit = DEFAULT_LIMITS.PAYSAFECARD
            state.paysafecardAvailableLimit = DEFAULT_LIMITS.PAYSAFECARD
            state.klarnaOrdersAvailableLimit = DEFAULT_LIMITS.KLARNA_OPEN_ORDERS
            state.payPalOrdersAvailableLimit = DEFAULT_LIMITS.PAYPAL_OPEN_ORDERS
          }

          calculateServiceFeeDiscountAndTotalAmount(state)
          saveStateInLocalStorage(state)
          state.isSuccess = true
          state.isLoading = false
        }
      )
      .addCase(
        loadProductDataOrderLimitDiscountAndOpenOrdersAmount.rejected,
        (state, action) => {
          state.isLoading = false
          state.isError = true
          state.message = action.payload
        }
      )
      .addCase(submitCart.pending, (state) => {
        state.isSubmitLoading = true
      })
      .addCase(submitCart.fulfilled, (state, action) => {
        state.isSubmitLoading = false
        state.isCartSubmitted = true
        state.isSubmitError = false
        saveStateInLocalStorage(state)
      })
      .addCase(submitCart.rejected, (state, action) => {
        state.isSubmitLoading = false
        state.isSubmitError = true
        state.message = action.payload
      })
  },
})

function saveStateInLocalStorage(state) {
  try {
    const encryptedCart = cryptoJS.AES.encrypt(
      JSON.stringify(state),
      process.env.REACT_APP_KREDU_SHOP_LOCAL_STORAGE_ENCRYPTION_KEY
    ).toString()
    localStorage.setItem('cart', encryptedCart)
  } catch (error) {
    state.isSuccess = false
    state.isError = true
  }
}

function calculateServiceFeeDiscountAndTotalAmount(state) {
  state.kredupayDiscountAmount = 0

  const prepaidFeePercentageWithoutDiscount = 8.4
  const bnplFeePercentageWithoutDiscount = 25.2
  //const prepaidFeePercentageWithDiscount = 5.88
  //const bnplFeePercentageWithDiscount = 25.20
  const vatRatePercentage = 19

  try {
    const isPrepaid = state.paymentMethod === 'SOFORT'

    const totalAmountWithoutServiceFee = state.cartItems.reduce((acc, x) => {
      return acc + (x.isInStock ? x.price * x.quantity : 0)
    }, 0)

    if (totalAmountWithoutServiceFee > 0) {
      state.totalAmountWithoutServiceFee = totalAmountWithoutServiceFee

      let serviceFeeBeforeVat = 0
      let serviceFeePercentage = isPrepaid
        ? prepaidFeePercentageWithoutDiscount
        : bnplFeePercentageWithoutDiscount

      serviceFeeBeforeVat =
        (totalAmountWithoutServiceFee * serviceFeePercentage) / 100

      //Flat service fee before VAT for users without discount
      // if (totalAmountWithoutServiceFee < 25)
      //   serviceFeeBeforeVat = isPrepaid ? 2.52 : 4.2
      // else if (totalAmountWithoutServiceFee < 50)
      //   serviceFeeBeforeVat = isPrepaid ? 4.2 : 6.3
      // else if (totalAmountWithoutServiceFee < 75)
      //   serviceFeeBeforeVat = isPrepaid ? 6.3 : 10.5
      // else if (totalAmountWithoutServiceFee < 100)
      //   serviceFeeBeforeVat = isPrepaid ? 8.4 : 12.6
      // else
      //   serviceFeeBeforeVat =
      //     (totalAmountWithoutServiceFee * serviceFeePercentage) / 100

      // if (state.kredupayDiscount === true) {
      //   let serviceFeeWithDiscountBeforeVat = 0
      //   serviceFeePercentage = isPrepaid
      //     ? prepaidFeePercentageWithDiscount
      //     : bnplFeePercentageWithDiscount

      //   //Flat service fee before VAT for users with discount
      //   if (totalAmountWithoutServiceFee < 25)
      //     serviceFeeWithDiscountBeforeVat = isPrepaid ? 2.1 : 2.52
      //   else if (totalAmountWithoutServiceFee < 50)
      //     serviceFeeWithDiscountBeforeVat = isPrepaid ? 2.52 : 4.2
      //   else if (totalAmountWithoutServiceFee < 75)
      //     serviceFeeWithDiscountBeforeVat = isPrepaid ? 4.2 : 5.88
      //   else if (totalAmountWithoutServiceFee < 100)
      //     serviceFeeWithDiscountBeforeVat = isPrepaid ? 5.88 : 7.56
      //   else
      //     serviceFeeWithDiscountBeforeVat =
      //       (totalAmountWithoutServiceFee * serviceFeePercentage) / 100

      //   //Kredupay discount
      //   state.kredupayDiscountAmount =
      //     serviceFeeBeforeVat - serviceFeeWithDiscountBeforeVat
      //   serviceFeeBeforeVat = serviceFeeWithDiscountBeforeVat
      // }

      //Rounding service fee before VAT
      serviceFeeBeforeVat = Math.round(serviceFeeBeforeVat * 100) / 100
      state.serviceFee = serviceFeeBeforeVat

      //VAT
      let serviceFeeVat = (serviceFeeBeforeVat * vatRatePercentage) / 100
      serviceFeeVat = Math.round(serviceFeeVat * 100) / 100
      state.serviceFeeVat = serviceFeeVat

      //Total amount
      let totalAmount =
        totalAmountWithoutServiceFee + serviceFeeBeforeVat + serviceFeeVat
      totalAmount = Math.round(totalAmount * 100) / 100
      state.totalAmount = totalAmount
    } else {
      state.serviceFee = 0
      state.kredupayDiscountAmount = 0
      state.totalAmount = 0
    }

    const paysafecardOrderAmount = state.cartItems.reduce((acc, x) => {
      return (
        acc +
        (x.isInStock && x.name.toLowerCase().includes('paysafe')
          ? x.price * x.quantity
          : 0)
      )
    }, 0)
    state.isPaysafecardLimitExceeded =
      paysafecardOrderAmount > state.paysafecardAvailableLimit
    state.isOpenKlarnaOrdersLimitExceeded =
      state.totalAmountWithoutServiceFee > state.klarnaOrdersAvailableLimit
  } catch (error) {
    state.isSuccess = false
    state.isError = true
  }
}

export const {
  reset,
  addToCart,
  setPaymentMethodInCart,
  resetRecentlyAddedCartProductId,
  increaseCartItemQuantity,
  decreaseCartItemQuantity,
  removeCartItem,
  clearCart,
  revertCartSubmit,
} = cartSlice.actions
export default cartSlice.reducer
